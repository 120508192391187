import { Fragment, useContext } from 'react';
// Context
// import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
// Components/ui
import { Box, Typography } from '@mui/material';
import { Loader, DocumentIcon } from './components';
// import { Markdown } from 'functions/Markdown';
// Types
import { DocumentExtension } from '../../../../../../../../../../../../../../redux/features/messagesSlice/types';
import {
  MessageDocument,
  MessageHeaderType,
} from '@trii/types/dist/Common/Messages';
// Hooks
import useSAS from '../../../../../../../../../../../../../../hooks/useSAS';
// Utils
import {
  getFileType,
  getTemplateHeaderFileType,
  Markdown,
} from '../../../../../../../../../../../../../../redux/features/messagesSlice/functions';

interface Props {
  documents: MessageDocument[];
  messageId: string;
  templateFileType?: MessageHeaderType;
}

const Document = ({ documents, messageId, templateFileType }: Props) => {
  // const {  getTemplateHeaderFileType, handleUpload } =
  //   useContext(messagesContext);

  const URLHandler = useSAS();

  // const handleTryAgain = () => {
  //   const filterDocument = documents.filter((document) => document.url === '');
  //   handleUpload(filterDocument, messageId);
  // };

  const handleDownload = async (url: string) => {
    const link = document.createElement('a');
    const fileName = url.split('/').pop();
    const accessURL = await URLHandler.getURLWithAccessToken(url);

    link.download = fileName || '';
    link.href = accessURL;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      {documents &&
        documents.length > 0 &&
        documents.map((document, i) => {
          const { id, mimeType, filename, url, caption } = document;
          const documentType =
            getFileType(mimeType) || getTemplateHeaderFileType(templateFileType);

          return (
            <Fragment key={i}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto auto 2rem',
                  gap: '1em',
                  placeItems: 'center',
                  padding: '0.5em 1em',
                  justifyContent: 'space-between',
                  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
                  margin: '3px 0',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                }}
                onClick={() => handleDownload(url)}
              >
                <Box
                  sx={{
                    width: '2.5rem',
                    height: '2.5rem',
                  }}
                >
                  <DocumentIcon
                    documentType={
                      documentType === 'image' || documentType === 'video'
                        ? documentType
                        : (filename?.split('.').pop() as DocumentExtension)
                    }
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: '0.8rem',
                    textAlign: 'center',
                    marginTop: 1,
                    color: (theme) => theme.palette.text.secondary,
                    maxWidth: '10rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  noWrap
                >
                  {filename}
                </Typography>
                {/* <Loader
                  url={url}
                  documentId={id}
                  handleTryAgain={handleTryAgain}
                  handleDownload={handleDownload}
                /> */}
              </Box>
              <Typography
                variant="caption"
                sx={{
                  fontSize: '13px',
                  lineHeight: 1.5,
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  textAlign: 'justify',
                  display: 'block',
                  padding: '8px',
                  color: (theme) => theme.palette.text.primary,
                  whiteSpace: 'pre-line',
                }}
                dangerouslySetInnerHTML={{
                  __html: Markdown(caption || '', false),
                }}
              />
            </Fragment>
          );
        })}
    </Box>
  );
};

export default Document;
