// Components/ui
import { Chat, PanelButton } from './components';
// Redux
import { useSelector } from 'react-redux';
import { selectOpenChats } from '../../../../redux/features/chatsSlice/selectors';
import { useEffect } from 'react';
// Db worker
import { conversationsDbWorker } from '../../../../db/conversationsDb';
import { chatsDbWorker } from '../../../../db/chatDb';
// Utils
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { setMessagesByEntity } from '../../../../redux/features/messagesSlice/messagesSlice';

const Chats = () => {
  const dispatch = useAppDispatch();

  const openChats = useSelector(selectOpenChats);

  useEffect(() => {
    conversationsDbWorker.onmessage = (event) => {
      if (event.data.action === 'getAllMessages' && event.data?.data?.length > 0) {
        dispatch(setMessagesByEntity(event.data.data));
      }

      // if (event.data.action === 'updateMessage' && event.data.data) {
      //   // Get the date of the message from the timestamp attribute (its a Date object)
      //   const date = moment(event.data.data.timestamp).format(FORMAT_DATE);
      //   // Search for the object of the date in the messages array
      //   const index = messagesRef.current.findIndex((item) => item.date === date);
      //   // Get the index of the message in the array of messages of the object of the date
      //   const messageIndex = messagesRef.current[index]?.messages.findIndex(
      //     (message) => message.id === event.data.data.id
      //   );
      //   if (messageIndex) {
      //     // Create a copy of the messages array
      //     const messagesCopy = [...messagesRef.current];

      //     // Update the message in the copy
      //     messagesCopy[index].messages[messageIndex] = event.data.data;

      //     // Update the state with the updated copy
      //     setMessages(messagesCopy);
      //   }
      // }
    };

    return () => {
      conversationsDbWorker.onmessage = null;
    };
  }, []);

  useEffect(() => {
    chatsDbWorker.onmessage = (event) => {
      if (event.data.action === 'getAllMessages' && event.data?.data?.length > 0) {
        dispatch(setMessagesByEntity(event.data.data));
      }

      // if (event.data.action === 'updateMessage' && event.data.data) {
      //   // Get the date of the message from the timestamp attribute (its a Date object)
      //   const date = moment(event.data.data.timestamp).format(FORMAT_DATE);
      //   // Search for the object of the date in the messages array
      //   const index = messagesRef.current.findIndex((item) => item.date === date);
      //   // Get the index of the message in the array of messages of the object of the date
      //   const messageIndex = messagesRef.current[index]?.messages.findIndex(
      //     (message) => message.id === event.data.data.id
      //   );
      //   if (messageIndex) {
      //     // Create a copy of the messages array
      //     const messagesCopy = [...messagesRef.current];

      //     // Update the message in the copy
      //     messagesCopy[index].messages[messageIndex] = event.data.data;

      //     // Update the state with the updated copy
      //     setMessages(messagesCopy);
      //   }
      // }
    };
  }, []);

  return (
    <>
      <PanelButton />
      <div
        style={{
          display: 'flex',
          gap: 6,
          right: '2.5rem',
          bottom: '0',
          position: 'absolute',
          alignItems: 'flex-end',
        }}
      >
        {openChats.map((data) => (
          <Chat key={data.id} data={data} />
        ))}
      </div>
    </>
  );
};

export default Chats;
