import { useEffect, useState } from 'react';
// Components/ui
import { Typography, styled } from '@mui/material';
import { AudioMessage, Document, Image, Map, ReplyMessage } from './components';
// Types
import {
  IMessage,
  MessageAudio,
  MessageDocument,
  MessageLocation,
  MessageReference,
  MessageSticker,
  MessageText,
} from '@trii/types/dist/Common/Messages';
import { ChatType } from '@trii/types/dist/Conversations';
import { MediaFile } from '../../../../../../../../../../../../redux/features/messagesSlice/types';
//Db
import chatsDb from '../../../../../../../../../../../../db/chatDb';
import conversationsDb from '../../../../../../../../../../../../db/conversationsDb';

const MessageBody = styled(Typography)({
  variant: 'body2',
  color: 'text.primary',
});

interface BodyProps {
  id: string;
  text: MessageText;
  audio: MessageAudio;
  audioUrl: string;
  files: MediaFile[];
  documents: MessageDocument[];
  locations: MessageLocation[];
  stickers: MessageSticker[];
  messageReference: MessageReference;
  chatType: ChatType;
}

const Body = ({
  id,
  text,
  audio,
  audioUrl,
  files,
  documents,
  locations,
  stickers,
  messageReference,
  chatType,
}: BodyProps) => {
  const [messageReferenceInfo, setMessageReferenceInfo] = useState<IMessage>(null);

  const getMessage = async () => {
    console.log('messageReference', messageReference);

    const newMessage =
      chatType === ChatType.EXTERNAL
        ? await conversationsDb.messages.get(messageReference.messageId)
        : await chatsDb.messages.get(messageReference.messageId);

    setMessageReferenceInfo(newMessage);
  };

  useEffect(() => {
    if (messageReference) {
      getMessage();
    }
  }, [messageReference]);

  return (
    <>
      {messageReference && (
        <ReplyMessage chatType={chatType} messageReply={messageReferenceInfo} />
      )}
      {text && (
        <MessageBody variant="body2" color="text.primary">
          {text.body}
        </MessageBody>
      )}
      {audio && (
        <AudioMessage
          audioUrl={audioUrl}
          messageId={id}
          // handleTryAgainAudio={handleTryAgainAudio}
        />
      )}
      {files && files?.length > 0 && (
        <Image
          // handleTryAgain={handleTryAgain}
          files={files}
          messageId={id}
          type="file"
          // isLoaded={isLoaded}
        />
      )}
      {documents && documents.length > 0 && (
        <Document documents={documents} messageId={id} />
      )}
      {locations && locations.length > 0 && (
        <Map locations={locations} messageId={id} />
      )}
      {stickers && stickers.length > 0 && (
        <Image
          // handleTryAgain={handleTryAgain}
          files={stickers}
          messageId={id}
          // isLoaded={isLoaded}
          type="sticker"
        />
      )}
    </>
  );
};

export default Body;
