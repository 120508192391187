// Components/ui
import { Box, useTheme } from '@mui/material';
import { Date, Message } from './components';
import { Fragment, useEffect } from 'react';
// Redux
import { useSelector } from 'react-redux';
import { selectMessagesById } from '../../../../../../../../redux/features/messagesSlice/selectors';
import { selectSpaceInfo } from '../../../../../../../../redux/features/spaceSlice/spaceSlice';
import { setMessagesByEntity } from '../../../../../../../../redux/features/messagesSlice/messagesSlice';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
// Utils
import moment from 'moment';
// Db
import conversationsDb from '../../../../../../../../db/conversationsDb';
import chatsDb from '../../../../../../../../db/chatDb';
// Types
import { MessageType } from '@trii/types/dist/Common/Messages';
import { ChatType } from '@trii/types/dist/Conversations';
// Hooks
import { useLiveQuery } from 'dexie-react-hooks';
import { useAutoScroll } from '../../../../../../../../hooks/useAutoScroll';

const FORMAT_DATE = 'DD/MM/YYYY';

interface BodyProps {
  id: string;
  conversationId: string;
  chatType: ChatType;
  filesDoneLoading: boolean;
}

const Body = ({ id, chatType, conversationId, filesDoneLoading }: BodyProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const today = moment();
  const todayFormat = today.format(FORMAT_DATE);
  const yesterday = today.subtract(1, 'days').format(FORMAT_DATE);

  const space = useSelector(selectSpaceInfo);
  const chatMessagesGroupedByDate = useSelector(selectMessagesById(id));

  useEffect(() => {
    console.log('chatMessagesGroupedByDate change: ', chatMessagesGroupedByDate);
  }, [chatMessagesGroupedByDate]);

  const externalMessagesDb = useLiveQuery(
    async () =>
      await conversationsDb.messages.where('contactId').equals(id).toArray(),
    [id, conversationsDb.messages]
  );
  const internalMessagesDb = useLiveQuery(
    async () =>
      await chatsDb.messages
        .where('shardKey')
        .equals(`${space.id}_chat_${conversationId}`)
        .toArray(),
    [chatsDb.messages]
  );

  // Pass the message length or messages themselves as the dependency to trigger the hook when new messages are added
  const { containerRef, handleScroll } = useAutoScroll<HTMLDivElement>(
    [
      chatMessagesGroupedByDate?.map((group) => group.messages.length).join(), // This ensures a change when new messages are added
    ],
    filesDoneLoading,
    conversationId
  );

  useEffect(() => {
    if (externalMessagesDb?.length > 0 && chatMessagesGroupedByDate?.length > 0) {
      const messagesIds = chatMessagesGroupedByDate
        .map((messageGroupByDate) =>
          messageGroupByDate.messages.map((msg) => msg.id)
        )
        .flat();
      let newMessages = externalMessagesDb.filter(
        (message) =>
          !messagesIds.includes(message.id) &&
          message.conversationId === conversationId
      );

      if (newMessages.length > 0) {
        const oldMessages = chatMessagesGroupedByDate
          .map((message) => message.messages)
          .flat();

        dispatch(setMessagesByEntity([...oldMessages, ...newMessages]));
      }
    }
  }, [externalMessagesDb]);

  useEffect(() => {
    if (internalMessagesDb?.length > 0 && chatMessagesGroupedByDate?.length > 0) {
      const messagesIds = chatMessagesGroupedByDate
        .map((messageGroupByDate) =>
          messageGroupByDate.messages.map((msg) => msg.id)
        )
        .flat();
      let newMessages = internalMessagesDb.filter(
        (message) =>
          !messagesIds.includes(message.id) &&
          message.conversationId === conversationId
      );

      // if (!firstLoad) {
      //   const newMessagesIds = newMessages.map((message) => message.id);
      //   const isMessageFromUser = newMessages.some(
      //     (message) => message.direction === 2
      //   );
      //   // Add 'read' attribute to each new message that enters after the first load
      //   newMessages = newMessages.map((message) => ({ ...message, read: false }));

      //   // Check if any of the new messages has the "direction" attribute === 2 (it means the message has OUT direction not IN)
      //   if (!isMessageFromUser) {
      //     // Set new unread messages ids without erasing the previous ones
      //     setUnreadMessagesIds((unreadMessagesIds) => [
      //       ...unreadMessagesIds,
      //       ...newMessagesIds,
      //     ]);

      //     // Check if its the first time there is unread messages in the state (firstUnreadMessageId === false)
      //     // and set the first unread message id
      //     if (!firstUnreadMessageId) {
      //       setFirstUnreadMessageId(newMessagesIds[0]);
      //     } else if (firstUnreadMessageId && isMessageFromUser) {
      //       // Reset the unread messages alert if the user sends a message
      //       setFirstUnreadMessageId(null);
      //     }
      //   }
      // }

      // This only takes in account new messages but not updates
      if (newMessages.length > 0) {
        const oldMessages = chatMessagesGroupedByDate
          .map((message) => message.messages)
          .flat();

        dispatch(setMessagesByEntity([...oldMessages, ...newMessages]));
      }
    }
  }, [internalMessagesDb]);

  return (
    <Box
      ref={containerRef}
      onScroll={handleScroll}
      sx={{
        borderTop: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        height: '320px',
        flexDirection: 'column',
        padding: '0.5rem',
        overflowY: 'auto',
      }}
    >
      {chatMessagesGroupedByDate?.map((messageGroup, i) => (
        <div key={i}>
          <Date day={messageGroup.date} today={todayFormat} yesterday={yesterday} />
          {messageGroup.messages
            .filter((message) => message.type !== MessageType.INFO)
            .map((message) => (
              <Message
                key={message.id} // Use a unique key for each message
                data={message}
                chatType={chatType}
                conversationId={conversationId}
              />
            ))}
        </div>
      ))}
      <div />
    </Box>
  );
};

export default Body;
