import { useEffect, useState } from 'react';
// Components/ui
import { Header, Body, Footer } from './components';
import { Box, Grow, LinearProgress } from '@mui/material';
// Types
import { ChatType } from '@trii/types/dist/Conversations';
import { ClientConversation } from '../../../../../../redux/features/chatsSlice/types';
// Redux
import { useSelector } from 'react-redux';
import { selectedTheme } from '../../../../../../redux/features/themeSlice/themeSlice';
import { selectMessagesStatusById } from '../../../../../../redux/features/messagesSlice/selectors';
import { getMessagesRelationId } from '../../../../../../redux/features/messagesSlice/functions';

type Props = {
  data: ClientConversation;
};

const Chat = ({ data }: Props) => {
  const [open, setOpen] = useState(false);

  const id = getMessagesRelationId(data);

  const onSelectedTheme = useSelector(selectedTheme);
  const messageStatus = useSelector(selectMessagesStatusById(id));

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Grow
      in={open}
      unmountOnExit
      style={{ transformOrigin: '0 0 0' }}
      {...(open ? { timeout: 1000 } : {})}
    >
      <Box
        sx={{
          width: '270px',
          //@ts-ignore
          border: `1px solid ${onSelectedTheme.palette.divider}`,
          //@ts-ignore
          backgroundColor: onSelectedTheme.palette.background.default,
          borderRadius: '4px',
          boxShadow: '0 -1px 6px 0 rgba(0,0,0,.25)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header
          chatId={data.id}
          userName={
            data.type === ChatType.EXTERNAL ? data.contactInfo.name : data.chatName
          }
          chatType={data.type}
        />
        {messageStatus === 'loading' && <LinearProgress />}
        <Body
          chatType={data.type}
          id={id}
          conversationId={data.id}
          filesDoneLoading={data.filesDoneLoading}
        />
        <Footer
          chatDocuments={data.documents}
          inputValue={data.inputValue}
          conversationId={data.id}
          channelInfo={data.channelInfo}
          contactInfoId={data.contactInfo?.id}
          remoteAddress={data.remoteAddress}
          chatId={data.id}
          chatType={data.type}
          recordAudioMode={data.recordAudioMode}
          chatMultimedia={data.files}
        />
      </Box>
    </Grow>
  );
};

export default Chat;
