import { useRef, useState, useEffect } from 'react';
// Redux
import { useAppDispatch } from './useAppDispatch';
import { useSelector } from 'react-redux';
import { selectUser, updateSas } from '../redux/features/userSlice/userSlice';
import { StorageAzureSAS } from '@trii/types/dist/Users';
// Utils
import { v4 as uuidv4 } from 'uuid';

export type UseSASType = {
  url: string;
  removeParams: (url: string) => string;
  getURLWithAccessToken: (baseUrl: string) => Promise<string>;
};

const useSAS = (initialState?: string): UseSASType => {
  const [url, setUrl] = useState(initialState);

  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  async function getSas() {
    const response = await dispatch(updateSas());
    const storageAzureSAS = response.payload as StorageAzureSAS;

    return storageAzureSAS.sas;
  }

  const getURLWithAccessToken = async (baseUrl: string) => {
    const expiredAtDate = new Date(user.storageAzureSAS.expireAt);
    let sas = '';

    if (expiredAtDate < new Date()) {
      sas = await getSas();
    } else {
      sas = user.storageAzureSAS.sas;
    }

    // const randomParam = uuidv4();

    // return `${baseUrl}${sas}&cacheId=${randomParam}`;
    return `${baseUrl}${sas}`;
  };

  const setAccessImageUrl = async (url: string) => {
    if (user.storageAzureSAS && url) {
      const URLWithAccessToken = await getURLWithAccessToken(url);
      setUrl(URLWithAccessToken);
    }
  };

  function removeSasToken(url: string) {
    return url.split('?sv=')[0];
  }

  function removeCacheId(url: string) {
    return url.split('&cacheId=')[0];
  }

  function removeParams(url: string) {
    let result = removeSasToken(url);
    result = removeCacheId(result);

    return result;
  }

  useEffect(() => {
    if (initialState) {
      const URL = removeSasToken(url);
      setAccessImageUrl(URL);
    }
  }, [initialState]);

  return {
    url,
    removeParams,
    getURLWithAccessToken,
  };
};

export default useSAS;
