import moment from 'moment';
// Components/ui
import { Typography, Box, styled } from '@mui/material';

const MessageHeader = styled(Box)({
  display: 'flex',
  gap: 1,
});

const MessageUsername = styled(Typography)({
  fontWeight: 'bold',
});

const MessageTimestamp = styled(Typography)({
  color: '#a0aec0',
  textAlign: 'right',
});

const Header = ({ username, timestamp }: { username: string; timestamp: Date }) => {
  return (
    <MessageHeader>
      <MessageUsername variant="body2" color="text.primary">
        {username}
      </MessageUsername>
      <MessageTimestamp variant="caption">
        {moment(timestamp).format('HH:mm')}
      </MessageTimestamp>
    </MessageHeader>
  );
};

export default Header;
